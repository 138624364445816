<template>
    <div class="paper">
      <v-row dense class=" ml-4">
          <v-col cols="1">
              <v-btn icon class="plus mt-5 ml-16" @click="addData"><v-icon x-large>mdi-plus</v-icon></v-btn>
          </v-col>
          <v-col cols="5" class="mt-4 mb-n2 ml-n10">
              <v-select
              v-model="idJudul"
              :items="data"
              item-text="type"
              item-value="id"
              label="Judul"
              solo
              ></v-select>
          </v-col>
          <v-col cols="5" class="mt-4">
          <v-btn :disabled="idJudul == null" @click="save">Simpan</v-btn>
          </v-col>
      </v-row>
    <quillEditor :content="content"
                  :options="editorOption"
                  @change="onEditorChange($event)">
    </quillEditor>
    <v-dialog v-model="DialogData" width="500px" height="600px">
      <v-card>
      <v-toolbar color="#a10115" dark class="mb-3">Tambah Data</v-toolbar>
      <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field class="pa-2" v-model="state" label="Judul" color="#a10115"></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-2">
          <v-btn :disabled="disablebtn" class="ml-12" width="80%" :dark="!disablebtn"  @click="save">Simpan</v-btn>
        </v-col>
      </v-row>
    </v-container>
      </v-card>
    </v-dialog>
    </div>
  </template>
   
  <script>
  // require styles
  import API from '@/services/http'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
   
  import { quillEditor } from 'vue-quill-editor'
  
    
    export default {
      components: {
      quillEditor
    },
      data () {
        return {
          data:[],
          state:null,
          content: '',
          DialogData:false,
          disablebtn:false,
          idJudul:null,
          editorOption: {
              //
          }
        }
      },
      watch:{
          idJudul(s){
              if(s != null){
                  this.content = this.data.find(x => x.id == s).html
              }
          }
      },
      methods: {
          getData(){
              API.get('custom').then(x=>{
                  this.data = x.data
              })
          },
          addData(){
              this.DialogData = true
          },
          save(){
            this.disablebtn = true
              let data = {
                  'state':this.state,
                  'id':this.idJudul,
                  'html':this.content
              }
              API.post('custom',data).then(x=>{
                  console.log(x)
                  this.$swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Data Disimpan',
                    animation: false,
                    showCloseButton: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true})
                    if(this.state != null){
                      this.DialogData = false
                      this.getData()
                      this.state = null
                    }
              })
          },
        onEditorChange({ quill, html, text }) {
          console.log('editor change!', quill, html, text)
          this.content = html
        }
      },
      computed: {
      //   editor() {
      //     return this.$refs.myQuillEditor.quill
      //   }
      },
      mounted() {
          this.getData()
        console.log('this is current quill instance object', this.editor)
      }
    }
  </script>
  <style>
  .plus{
    color: #a10115 !important;
  }
  .btn{
    background-color: #a10115 !important;
  }
  .paper{
    background-color: white !important;
  }
</style>